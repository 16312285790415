<template lang="pug">
  v-dialog(
    v-model="value"
    max-width="600"
  )
    v-card
      v-card-title Выберите версию терминала
      v-card-text
        v-row
          v-col(cols="12")
            v-autocomplete(
              v-model="version"
              label="Версия"
              :errorMessages="errorMessage"
              :items="versions"
              item-value="id"
              item-text="name"
              :disabled="$root.isDisabled"
              dense
              outlined
            )
        v-row
          v-col(cols="6")
            v-btn(
              :disabled="disabled"
              color="blue"
              @click="onAbort"
              block
              outlined
            ) Отмена
          v-col(cols="6")
            v-btn.white--text(
              color="blue"
              :loading="loading"
              :disabled="disabled"
              @click="onChange"
              block
            ) Выбрать
</template>

<script>
export default {
  name: 'ChangeVersion',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    versions: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      version: '',
      errorMessage: null,
    };
  },

  methods: {
    onAbort() {
      this.version = '';
      this.reset();
      this.$emit('input', false);
    },

    onChange() {
      this.reset();

      if (!this.version) {
        this.errorMessage = 'Выберите версию';
        return;
      }

      this.$emit('change', this.version);
    },

    reset() {
      this.errorMessage = null;
    },
  },
};
</script>
